// fonts sizes
$font-xsmall: 0.694vw; //10px
$font-small: 0.833vw; //12px
$font-medium: 1.111vw; //16px
$font-large: 1.389vw; //20px
$font-xlarge: 1.667vw; //24px

//colors
$color-primary-dark: #ff5e2c;
$color-primary-medium: #ee5929;
$color-primary-light: #ffd4ba;

// fonts sizes tablet
$font-xsmall-tablet: 1.302vw; //10px
$font-small-tablet: 1.563vw; //12px
$font-medium-tablet: 2.083vw; //16px
$font-large-tablet: 2.604vw; //20px
$font-xlarge-tablet: 3.125vw; //24px

// fonts sizes mobile
$font-xsmall-mobile: 1.736vw; //10px
$font-small-mobile: 2.083vw; //12px
$font-medium-mobile: 2.778vw; //16px
$font-large-mobile: 3.472vw; //20px
$font-xlarge-mobile: 4.167vw; //24px

@mixin font-size($font-size) {
  @if ($font-size == $font-xsmall) {
    font-size: $font-size;
    @media screen and (max-width: 768px) {
      font-size: $font-xsmall-tablet;
    }
    @media screen and (max-width: 576px) {
      font-size: $font-xsmall-mobile;
    }
  } @else if ($font-size == $font-small) {
    font-size: $font-size;
    @media screen and (max-width: 768px) {
      font-size: $font-small-tablet;
    }
    @media screen and (max-width: 576px) {
      font-size: $font-small-mobile;
    }
  } @else if ($font-size == $font-medium) {
    font-size: $font-size;
    @media screen and (max-width: 768px) {
      font-size: $font-medium-tablet;
    }
    @media screen and (max-width: 576px) {
      font-size: $font-medium-mobile;
    }
  } @else if ($font-size == $font-large) {
    font-size: $font-size;
    @media screen and (max-width: 768px) {
      font-size: $font-large-tablet;
    }
    @media screen and (max-width: 576px) {
      font-size: $font-large-mobile;
    }
  } @else if ($font-size == $font-xlarge) {
    font-size: $font-size;
    @media screen and (max-width: 768px) {
      font-size: $font-xlarge-tablet;
    }
    @media screen and (max-width: 576px) {
      font-size: $font-xlarge-mobile;
    }
  }
}
