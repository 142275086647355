@import "../../global/scss/variables.scss";

.modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-inner-container {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  height: 70vh;
  overflow-y: auto;
  width: 60vw;
}
.new-onboarding-modal-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 1rem;
  .title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .divider {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin: 1rem 0;
  }
  .new-onboarding-modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .new-onboarding-modal-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .onboarding-input {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-size: 1rem;
      font-weight: 500;
    }
    .pre-text {
      padding: 0px 0.4rem 0.4rem 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }
  }
  .channel-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    .channel-chip {
      background-color: #f5f5f5;
      border-radius: 30px;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      .logo {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        object-fit: contain;
      }
      .name {
        font-size: 1rem;
        font-weight: 500;
        color: #000000;
      }
    }
  }
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
    .submit_button {
      background-color: darken($color-primary-light, 5%);
      color: #000000;
      &:hover {
        background-color: darken($color-primary-light, 10%);
      }
      &:active {
        background-color: darken($color-primary-light, 15%);
      }
    }
    .cancel_button {
      color: #000000;
      border-color: darken($color-primary-light, 5%);
      &:hover {
        background-color: darken($color-primary-light, 10%);
      }
      &:active {
        background-color: darken($color-primary-light, 15%);
      }
    }
  }
}

@media (max-width: 576px) {
  .modal-inner-container {
    width: 90vw;
  }
}
