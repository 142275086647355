@import "./global/scss/variables.scss";

.app-container {
  height: 100vh;
  width: 100vw;
  display: flex;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-button {
  display: none;
}
