.splash-container {
  background-color: #22292f;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .splash-gif {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .splash-voosh-logo {
    position: absolute;
    height: 100vh;
    object-fit: contain;
    animation-name: fadeOpen, sizeGrow, sizeCollapse;
    animation-duration: 5s, 2s, 2s;
    animation-iteration-count: 1, infinite, infinite;
    animation-direction: normal, alternate, alternate;
    @keyframes fadeOpen {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes sizeGrow {
      from {
        width: 48vw;
      }
      to {
        width: 50vw;
      }
    }

    @keyframes sizeCollapse {
      from {
        width: 50vw;
      }
      to {
        width: 48vw;
      }
    }

    @media (min-width: 992px) {
      @keyframes sizeGrow {
        from {
          width: 18vw;
        }
        to {
          width: 20vw;
        }
      }
      @keyframes sizeCollapse {
        from {
          width: 20vw;
        }
        to {
          width: 18vw;
        }
      }
    }
  }
}
