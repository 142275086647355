@import "../../global/scss/variables.scss";

.boarding-list-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 1rem;
    .voosh-logo {
      width: 100px;
      object-fit: contain;
    }
    .logout {
      background-color: #fff;
      color: #000000;
      border: 1px solid darken($color-primary-light, 5%);
      @include font-size($font-small);
      font-weight: 600;
      padding: 5px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      border-radius: 5px;
      &:hover {
        background-color: darken($color-primary-light, 5%);
      }
      &:active {
        background-color: darken($color-primary-light, 10%);
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .action-container {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .search {
      width: 250px;
    }
    .new_button {
      background-color: darken($color: $color-primary-dark, $amount: 5%);
      &:hover {
        background-color: darken($color: $color-primary-dark, $amount: 10%);
      }
      &:active {
        background-color: darken($color: $color-primary-dark, $amount: 15%);
      }
    }
  }
  .table-container {
    padding: 1rem;
    .chip-else {
      background-color: #f5f5f5;
      color: #000000;
      padding: 5px 10px;
      border-radius: 20px;
      width: fit-content;
      margin: 0px;
      margin-left: auto;
    }
  }
}
